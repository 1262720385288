<template>
    <div class="footer">
        <div class="footer-company">
            <div class="company-logo">
                <router-link to="/">
                    <img src="@/assets/images/Group 479.png" alt="SILOHO" />
                </router-link>
            </div>
            <div class="company-info">
                <h1>We Want You To Be Thrilled!!</h1>
                <p>
                    We promised you a space that reflects the true you. Our goal
                    is to increase the quality of your life through your living
                    space and we work tirelessly for that. And if you are not
                    happy, then we are not as well. We take our promise
                    seriously and that's why we offer the Happiness Guarantee.
                </p>
                <div class="follows-on">
                    <p>Follow us on</p>
                    <div class="social-icons">
                        <a
                            href="https://www.facebook.com/Siloho-India-100137499271516/"
                            target="_blank"
                        >
                            <font-awesome-icon :icon="['fab', 'facebook']" />
                        </a>
                        <a
                            href="https://www.instagram.com/siloho_india/"
                            target="_blank"
                        >
                            <font-awesome-icon :icon="['fab', 'instagram']" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/siloho/"
                            target="_blank"
                        >
                            <font-awesome-icon :icon="['fab', 'linkedin']" />
                        </a>
                        <!-- <a href="#">
                            <font-awesome-icon :icon="['fab', 'twitter']" />
                        </a>
                        <a href="#">
                            <font-awesome-icon :icon="['fab', 'pinterest']" />
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-links-section">
            <div class="footer-links-group">
                <h1>Company</h1>
                <ul>
                    <router-link
                        :to="{
                            name: 'DesignIdeasListPage',
                        }"
                    >
                        <li class="nav-item">
                            Design Ideas
                        </li>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'DesignersListPage',
                        }"
                    >
                        <li class="nav-item">
                            Our Designers
                        </li>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'BuildersListPage',
                        }"
                    >
                        <li class="nav-item">
                            Our Partners
                        </li>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'ContactPage',
                        }"
                    >
                        <li class="nav-item">
                            Contact Us
                        </li>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'AboutUsPage',
                        }"
                    >
                        <li class="nav-item">
                            About Us
                        </li>
                    </router-link>
                </ul>
            </div>
            <div class="footer-links-group">
                <h1>Explore</h1>
                <ul>
                    <li class="nav-item">
                        <a class="nav-link" href="https://blog.siloho.com/">
                            Blog
                        </a>
                    </li>
                    <router-link
                        :to="{
                            name: 'AffiliateHomePage',
                        }"
                    >
                        <li class="nav-item">
                            Affiliate
                        </li>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'FAQPage',
                        }"
                    >
                        <li class="nav-item">
                            FAQ
                        </li>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'TCPage',
                        }"
                    >
                        <li class="nav-item">
                            T&C
                        </li>
                    </router-link>
                </ul>
            </div>
            <div class="footer-links-group">
                <h1>Contact Us</h1>
                <ul>
                    <li>
                        <a href="mailto:info@siloho.com"
                            ><span>Email</span> info@siloho.com</a
                        >
                    </li>
                    <li><span>Instagram</span> @siloho_india</li>
                    <li>
                        <a href="tel:+918805806805"
                            ><span>Mumbai Off</span> +91 8 805 806 805</a
                        >
                    </li>
                    <li>
                        <a href="tel:+917373353557"
                            ><span>Goa Off</span> +91 7 373 353 557</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        },
    },
}
</script>

<style lang="scss" scoped>
@import './Footer.scss';
</style>
