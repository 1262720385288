import Api from 'utils/Api'

export function getAffiliate(url){

    return Api
    .get(`Miscellaneous/listicles-details?link=${url}`)
    .then(response => {
        return response.data
    })

    .catch(e => {
        return { status: e.response.status, data: e.response.data }
    })

}

export function getAffiliateHome(){

    return Api
    .get(`Miscellaneous/listicles`)
    .then(response => {
        return response.data
    })

    .catch(e => {
        return { status: e.response.status, data: e.response.data }
    })

}