<template>
    <div class="affiliate-view">
        <div class="head-display">
            <div class="display-image">
                <div class="img"></div>
                <div class="display-info">
                    Helping you move in with the best recommendations
                </div>
            </div>
        </div>
        <div
            class="affiliate-view-body"
            v-if="Object.keys(affiliate).length > 0"
        >
            <div class="page-info">
                <div class="info-head">
                    {{ affiliate.payloads.title }}
                </div>
                <div class="info-body">
                    {{ affiliate.payloads.description }}
                </div>
            </div>
            <div
                class="product-info"
                v-for="(item, index) in affiliate.payloads.products"
                :key="item + '_' + index"
            >
                <div class="products">
                    <div v-html="item.product_link" class="p-img">
                        {{ item.product_link }}
                    </div>
                    <div class="p-info">
                        <div class="info-head">
                            {{ item.item_name }}
                        </div>
                        <p class="short_des" v-html="item.short_description">
                            {{ item.short_description }}
                        </p>
                        <p class="p_des"></p>
                        <!-- <Button
                            name="Buy"
                            primary
                            :handleClick="goToBuy"
                            :data-id="item.product_id + item.product_name"
                        /> -->
                    </div>
                </div>
            </div>
            <div class="footer-info">
                {{ affiliate.payloads.footer }}
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import AffiliatePage from 'store/modules/AffiliatePage'

import Footer from 'componentsv2/Footer'
// import Button from 'componentsv2/Button'

export default {
    name: 'AffiliatePage',
    components: {
        // Button,
        Footer,
    },
    mixins: [RegisterStoreModule],
    data() {
        return {
            title: '',
        }
    },
    computed: {
        ...mapState({
            affiliate: state => state.AffiliatePage.affiliate,
        }),
    },
    metaInfo() {
        return {
            title: 'SILOHO',
            titleTemplate: `%s | ${this.title}`,
            bodyAttrs: {
                class: ['affiliated-page'],
            },
        }
    },
    created() {
        this.registerStoreModule('AffiliatePage', AffiliatePage)
        this.fetchAffiliate(this.$route.query.link).then(() => {
            this.title = this.affiliate.payloads.tab_title
        })
    },
    destroyed() {
        // this.$store.unregisterModule('AffiliatePage')
    },
    methods: {
        ...mapActions({
            fetchAffiliate: 'fetchAffiliate',
        }),
        // goToBuy(e) {
        //     console.log(e.currentTarget.attr('data-id'))
        // },
    },
}
</script>

<style lang="scss">
@import 'AffiliatePage.scss';
</style>
