var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-company"},[_c('div',{staticClass:"company-logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/Group 479.png"),"alt":"SILOHO"}})])],1),_c('div',{staticClass:"company-info"},[_c('h1',[_vm._v("We Want You To Be Thrilled!!")]),_c('p',[_vm._v(" We promised you a space that reflects the true you. Our goal is to increase the quality of your life through your living space and we work tirelessly for that. And if you are not happy, then we are not as well. We take our promise seriously and that's why we offer the Happiness Guarantee. ")]),_c('div',{staticClass:"follows-on"},[_c('p',[_vm._v("Follow us on")]),_c('div',{staticClass:"social-icons"},[_c('a',{attrs:{"href":"https://www.facebook.com/Siloho-India-100137499271516/","target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'facebook']}})],1),_c('a',{attrs:{"href":"https://www.instagram.com/siloho_india/","target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'instagram']}})],1),_c('a',{attrs:{"href":"https://www.linkedin.com/company/siloho/","target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'linkedin']}})],1)])])])]),_c('div',{staticClass:"footer-links-section"},[_c('div',{staticClass:"footer-links-group"},[_c('h1',[_vm._v("Company")]),_c('ul',[_c('router-link',{attrs:{"to":{
                        name: 'DesignIdeasListPage',
                    }}},[_c('li',{staticClass:"nav-item"},[_vm._v(" Design Ideas ")])]),_c('router-link',{attrs:{"to":{
                        name: 'DesignersListPage',
                    }}},[_c('li',{staticClass:"nav-item"},[_vm._v(" Our Designers ")])]),_c('router-link',{attrs:{"to":{
                        name: 'BuildersListPage',
                    }}},[_c('li',{staticClass:"nav-item"},[_vm._v(" Our Partners ")])]),_c('router-link',{attrs:{"to":{
                        name: 'ContactPage',
                    }}},[_c('li',{staticClass:"nav-item"},[_vm._v(" Contact Us ")])]),_c('router-link',{attrs:{"to":{
                        name: 'AboutUsPage',
                    }}},[_c('li',{staticClass:"nav-item"},[_vm._v(" About Us ")])])],1)]),_c('div',{staticClass:"footer-links-group"},[_c('h1',[_vm._v("Explore")]),_c('ul',[_vm._m(0),_c('router-link',{attrs:{"to":{
                        name: 'AffiliateHomePage',
                    }}},[_c('li',{staticClass:"nav-item"},[_vm._v(" Affiliate ")])]),_c('router-link',{attrs:{"to":{
                        name: 'FAQPage',
                    }}},[_c('li',{staticClass:"nav-item"},[_vm._v(" FAQ ")])]),_c('router-link',{attrs:{"to":{
                        name: 'TCPage',
                    }}},[_c('li',{staticClass:"nav-item"},[_vm._v(" T&C ")])])],1)]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://blog.siloho.com/"}},[_vm._v(" Blog ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-links-group"},[_c('h1',[_vm._v("Contact Us")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"mailto:info@siloho.com"}},[_c('span',[_vm._v("Email")]),_vm._v(" info@siloho.com")])]),_c('li',[_c('span',[_vm._v("Instagram")]),_vm._v(" @siloho_india")]),_c('li',[_c('a',{attrs:{"href":"tel:+918805806805"}},[_c('span',[_vm._v("Mumbai Off")]),_vm._v(" +91 8 805 806 805")])]),_c('li',[_c('a',{attrs:{"href":"tel:+917373353557"}},[_c('span',[_vm._v("Goa Off")]),_vm._v(" +91 7 373 353 557")])])])])}]

export { render, staticRenderFns }