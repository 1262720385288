import { getAffiliate, getAffiliateHome } from 'api/affiliateApi'


const AmazonAffiliatePage = {
    state: () => ({
        affiliate: [],
        affiliate_details: []
    }),

    mutations: {
        setAffiliate(state, payload){
            state.affiliate = payload
        },
        setAffiliateDetails(state, payload){
            state.affiliate_details = payload
        },
    },

    getters: {},

    actions: {
        fetchAffiliate({commit},url){
            return getAffiliate(url)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setAffiliate', payload)
                        return payload
                    }
                })
        },
        fetchAffiliateDetails({commit},id){
            return getAffiliateHome(id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setAffiliateDetails', payload)
                        return payload
                    }
                })
        },

    }
}

export default AmazonAffiliatePage;